/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

html,
body,
app-root,
app-connexion {
  height: 100%;
  min-height: 500px;
  scrollbar-width: thin;
}

body,
app-root,
app-connexion {
  overflow: auto;
}

body::-webkit-scrollbar-track {
  margin-top: 9px !important;
  margin-bottom: 0px !important;
  margin-left: 9px !important;
}

body::-webkit-scrollbar-thumb {
  background-color: #e1e1e1;
  border: 8px solid transparent;
  border-radius: 20px;
  background-clip: padding-box;
}

body::-webkit-scrollbar {
  width: 24px;
  height: 24px;
}

body::-webkit-scrollbar-corner {
  background-color: transparent;
}

body {
  background-color: #f0f0f0 !important;
  font-family: 'Open Sans' !important;
}

ngb-modal-window.d-block {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding-top: 120px;
}

@media (min-width: 750px) {
  .modal-dialog {
    min-width: 700px;
  }
}

.modal-content {
  font-family: 'Open Sans';
}

.modal-dialog {
  max-width: 700px !important;
}

input:focus,
button:focus {
  border-color: #ced4da !important;
  box-shadow: none !important;
}

.main-content {
  position: relative;
  padding: 120px 40px 40px 40px;
  height: 100% !important;
  min-height: 500px;
  justify-content: center;
  align-items: center;
}

@media (max-width: 670px) {
  .main-content {
    overflow: auto;
    padding: 190px 10px 20px 10px;
    align-items: flex-start;
  }
}

.btn-danger,
.btn-danger:disabled,
.btn-danger.disabled,
.btn-danger:focus {
  color: #fff;
  background-color: #fcce42;
  border-color: #fcce42;
}

.btn-danger:hover {
  background-color: #e7ae0a;
  border-color: #e7ae0a;
}

.btn-success,
.btn-success:disabled,
.btn-success.disabled,
.btn-success:focus {
  color: #fff;
  background-color: #fd836b;
  border-color: #fd836b;
}

.btn-success:hover {
  color: #fff;
  background-color: #e5634b;
  border-color: #e5634b;
}

/** ************************ Datatable ************************ */

@media (max-width: 670px) {
  table {
    font-size: 13px !important;
  }
}

.paginate_button {
  background: none !important;
  border: none !important;
}

#DataTables_Table_0_wrapper {
  font-family: 'Open Sans' !important;

  .paginate_button:active {
    box-shadow: none;
  }
  .paginate_button:hover:not(.current):not(.disabled) {
    color: lightslategray !important;
  }
}

.dataTables_filter label input {
  background-color: #ffffff !important;
}

@media (max-width: 600px) {
  #DataTables_Table_0_wrapper {
    width: fit-content;
  }
}

.dataTables_filter {
  width: 100%;

  label {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  input {
    width: 50%;
    border-radius: 50px !important;
    padding: 0px 20px !important;
    height: 50px !important;
    margin-bottom: 30px;
    margin-top: 10px;
  }

  input:focus-visible {
    outline: none !important;
  }
}

.dataTables_paginate {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

tbody {
  background-color: #ffffff;
}

table.dataTable.stripe tbody tr.odd {
  background-color: rgba(253, 131, 107, 0.1);
}

table.dataTable tbody tr:hover {
  background-color: rgba(253, 131, 107, 0.5) !important;
}

table.dataTable thead th,
table.dataTable thead td,
table.dataTable.no-footer {
  border-bottom-color: #919191 !important;
}

table.dataTable tbody th,
table.dataTable tbody td {
  padding: 25px 10px !important;
}

@media (max-width: 576px) {
  .dataTables_filter label {
    justify-content: start;
  }

  .dataTables_filter input {
    width: 100%;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  box-shadow: none !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #ff4866 !important;
  font-weight: bold;
}
/* -----------------------------------------------------------------------------------*/
@media (max-width: 576px) {
  ngb-modal-window.d-block {
    padding-top: 190px;
  }
}
button:focus-visible {
  outline: none;
}

.modale-error {
  .modal-footer {
    justify-content: center;
  }

  .modal-body {
    text-align: center;
    color: #dc3545;
    font-style: italic;
  }

  p:first-child {
    margin-bottom: 5px;
  }

  .modal-header {
    justify-content: center;
  }

  h4 {
    font-weight: bold;
    color: #dc3545;
  }
}

.modale-download {
  .modal-footer,
  .modal-header {
    justify-content: center;
  }
  .modal-body {
    text-align: center;
  }
}

.ngb-tp-chevron:before {
  border-color: #fd836b;
}

.ngb-tp-input-container button {
  padding: 0px;
}

.ngb-tp-input-container button.disabled {
  display: none;
}

.ngb-tp-input[disabled] {
  background-color: transparent;
  border: none;
}

.invalidTime input,
.invalidTime input:focus {
  border-color: red !important;
  box-shadow: 0 0 0 0.25rem rgb(255 0 0 / 25%) !important;
}

.ngb-tp-input-container {
  width: 3em !important;
}

.ngb-dp-day.disabled {
  color: #e1e1e1;
}

.ngb-dp-weekday,
.ngb-dp-header .btn-link {
  color: #fd836b !important;
}

button {
  color: #000;
  text-align: center;
}
